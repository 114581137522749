import React, { useMemo } from "react";

import SubscriptionCardsBlock from "../../components/SubscriptionCardsBlock/SubscriptionCardsBlock";

import EnrollSubscription from "../SubscriptionDetailPage/EnrollSubscription";

import { useQuery } from "@apollo/client";
import { CARD_TEMPLATES, CARD_TEMPLATES_GROUP } from "../../../../../graphql/queries/queriesCard";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

import { useMediaQuery } from "../../../../../hooks/useMediaQuery";
import SubscriptionCardMobile from "../../components/SubscriptionCardsBlock/SubscriptionCardMobile";

function SubscriptionPage({ cardOpenPriceEnabled }) {
  const router = useRouter();
  const [t, { language }] = useTranslation();

  const { asPath, query } = router;

  const { isSuccess, isPayment } = query;

  const successPayment = useMemo(() => {
    return isPayment ? t("payment_successfully_processed") : t("enroll_successfully_submitted");
  }, [isPayment, t]);

  const { data: { cardTemplates = [] } = {} } = useQuery(CARD_TEMPLATES, {
    variables: { language },
  });

  const { data: { cardTemplateGroups = [] } = {} } = useQuery(CARD_TEMPLATES_GROUP, { variables: { language } });

  const { groups, allCards } = useMemo(() => {
    if (!cardTemplateGroups?.length || !cardTemplates?.length) return { groups: [], cards: {} };

    const groupsMap = new Map();

    cardTemplates.forEach((card) => {
      const { group } = card;
      const { id, title } = group;

      if (!groupsMap.has(id)) {
        groupsMap.set(id, { title, cards: [] });
      }

      groupsMap.get(id).cards.push(card);
    });

    const groups = Array.from(groupsMap.keys());
    const allCards = Object.fromEntries(groupsMap);

    return { groups, allCards };
  }, [cardTemplateGroups, cardTemplates]);

  const isDesktop = useMediaQuery("(min-width:500px)");

  return (
    <>
      {isSuccess !== undefined && (
        <EnrollSubscription
          data={query}
          onClose={() =>
            router.replace({ pathname: asPath.split("?")[0], query: {} }, undefined, {
              shallow: true,
            })
          }
          title={successPayment}
        />
      )}

      <div className="subscription-wrapper">
        <div className="subscription-wrapper__content">
          <div className="container">
            {!!groups.length ? (
              groups.map((groupId) => {
                const { title, cards } = allCards[groupId];
                return (
                  <div className="subscription-wrapper__inner" key={groupId}>
                    <h3 className="subscription-wrapper__inner-title">{title}</h3>

                    {isDesktop ? (
                      <SubscriptionCardsBlock allCards={cards} isArrows cardOpenPriceEnabled={cardOpenPriceEnabled} />
                    ) : (
                      <div className="subscription-cards-block subscription-card-mobile-wrapper">
                        {cards.map(({ id, ...props }) => {
                          return (
                            <SubscriptionCardMobile
                              {...{
                                ...props,
                                id: +id,
                                isSubscription: false,
                                cardOpenPriceEnabled,
                              }}
                              key={id}
                            />
                          );
                        })}
                      </div>
                    )}
                  </div>
                );
              })
            ) : (
              <div className="subscription-wrapper__inner">
                <h3 className="subscription-wrapper__inner-title">{t("no_subscriptions_available")}</h3>
              </div>
            )}
          </div>
        </div>
      </div>

      <style jsx global>{`
        @import "./SubscriptionPage.scss";
        @import "../../components/SubscriptionCardsBlock/SubscriptionCardsBlock.scss";
      `}</style>
    </>
  );
}

export default SubscriptionPage;

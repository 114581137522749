import React, { memo } from "react";

import { AlertBlock } from "../../../../../../common/AlertBlock";

import { OptionCardWrapper } from "../OptionCard/OptionCardWrapper";
import { useTranslation } from "next-i18next";

const OptionAlert = memo(({ visitId, title, availableOptions, onClose, editable }) => {
  const [t] = useTranslation();

  return (
    <AlertBlock onClose={onClose} initialWidth>
      <>
        <h2 className="sign-up-section__title">{title}</h2>
        {availableOptions.map(({ id, ordered, optionId, ...props }) => (
          <OptionCardWrapper
            {...props}
            ordered={ordered}
            key={id}
            className={"button-wrap"}
            visitId={visitId}
            id={ordered ? optionId : id}
            editable={editable}
            addTitle={t("add")}
            removeTitle={t("remove")}
          />
        ))}
      </>
    </AlertBlock>
  );
});

export { OptionAlert };

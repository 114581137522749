import React from "react";
import PropTypes from "prop-types";

function MobileBlock({ dateTime, timeTitle, priceTitle, activityTitle, title, description, descriptionHtml }) {
  return (
    <div className="training-about-mob">
      <div className="training-about-mob__date-block">{dateTime}</div>
      {timeTitle && <div className="training-about-mob__date-block">{timeTitle}</div>}
      <div className="training-about-mob__title-block">
        <div className="training-about-mob__main-title">
          <span></span>
          {title}
        </div>

        <div className="training-about-mob__second-title">{activityTitle}</div>
        {priceTitle && <div className="training-about-mob__duration-title">{priceTitle}</div>}
      </div>

      <div
        className="training-about-mob__description description-html"
        style={descriptionHtml ? null : { textAlign: "justify", whiteSpace: "pre-wrap" }}
        dangerouslySetInnerHTML={{ __html: description }}
      ></div>
    </div>
  );
}

MobileBlock.propTypes = {
  description: PropTypes.string,
  descriptionHtml: PropTypes.bool,
  dateTime: PropTypes.string,
  priceTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  activityTitle: PropTypes.string,
  title: PropTypes.string,
};
MobileBlock.defaultProps = {
  description: "",
  descriptionHtml: false,
  dateTime: "",
  priceTitle: "",
  activityTitle: "",
  title: "",
};

export default MobileBlock;

import { useEffect } from "react";

import { TOGGLE_SCHEDULE_BUTTONS } from "../../components/ScheduleBlock/languageConstants";

import { useRouter } from "next/router";

export const useTypeSwitcher = ({ scheduleDefaultView, setActive, elementBySize }) => {
  const router = useRouter();
  const {
    query: {
      club,
      typeSlug: [type],
    },
  } = router;

  useEffect(() => {
    if (!type) return;

    const isAvailable = TOGGLE_SCHEDULE_BUTTONS.some(({ slug, onlyDesctop }) => slug === type && (!elementBySize || !onlyDesctop));

    if (isAvailable) {
      return setActive(type);
    }

    if (elementBySize) {
      setActive("day");
    } else {
      router.replace(
        {
          pathname: `/club/${club}/schedule/${scheduleDefaultView}`,
        },
        `/club/${club}/schedule/${scheduleDefaultView}`,
        { shallow: true }
      );
    }
  }, [type, elementBySize, scheduleDefaultView]);

  return null;
};

import { useCallback, useEffect, useRef, useState } from "react";

import { useRouter } from "next/router";
import { GET_CLIENT_EVENT_BY_PROFILE, GET_EVENT } from "../../../../../graphql/queries/queriesEvent";
import { useAuth } from "../../../../../utils/auth";

import { useTranslation } from "next-i18next";
import { useLazyQuery } from "@apollo/client";
import { useFamilyData } from "../../../../../utils/apollo/reactivities";

export default function useTrainingRequestData({ showPayments = true, eventIdDefault = null } = {}) {
  const router = useRouter();
  const {
    query: { familyProfileId, eventId: routeEventId },
  } = router;

  const {
    i18n: { language },
  } = useTranslation();

  const { isAuthorized, accessToken } = useAuth();

  const family = useFamilyData();

  const [familyId, setFamilyId] = useState(null);
  const [promocode, setPromocode] = useState("");
  //
  const hasOwnPromocode = useRef(false);
  const isFirstRequestDone = useRef(false);

  const [{ data, isLoading }, setData] = useState({
    data: {},
    isLoading: true,
  });

  useEffect(() => {
    if (!family.length) return;

    setFamilyId(familyProfileId ?? family?.[0]?.id);
  }, [family, familyProfileId]);

  const eventId = eventIdDefault ?? routeEventId;

  const [getEvent, { error }] = useLazyQuery(isAuthorized ? GET_CLIENT_EVENT_BY_PROFILE : GET_EVENT, {
    fetchPolicy: "cache-and-network",
  });

  const refetch = useCallback(() => {
    getEvent({
      variables: {
        showPayments,
        eventId: +eventId,
        ...(isAuthorized && { familyId: +familyId, ...(promocode && { promocode }) }),
      },
      onCompleted: ({ event }) => {
        if (!isAuthorized) {
          return setData({
            data: event,

            isLoading: false,
          });
        }
console.log('dd')
        const receivedPromocode = event?.priceInfo?.promocode;

        if (!hasOwnPromocode.current && !isFirstRequestDone.current) {
          if (receivedPromocode) {
            setPromocode(receivedPromocode);
            hasOwnPromocode.current = true;
          } else isFirstRequestDone.current = true;
        }
        setData({ data: event, isLoading: false });
      },

      onError: (e) => {
        console.log(e);
        //сбрасывать если промокод не похдходит
        if (promocode) setPromocode("");
      },
    });
  }, [isAuthorized, accessToken, showPayments, language, eventId, familyId, getEvent, promocode]);

  useEffect(() => {
    if (!eventId) return; //|| (isAuthorized && familyId === null)

    if (hasOwnPromocode.current && !isFirstRequestDone.current) {
      isFirstRequestDone.current = true;
      return;
    }

    refetch();
  }, [eventId, refetch]);

  return { data, isLoading, error, familyId, setFamilyId, promocode, setPromocode, hasOwnPromocode };
}

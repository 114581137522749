import { useState, useMemo, useCallback } from "react";

import * as calendar from "../../components/ToggleBlockOfData/calendarUtil";
import {
  startOfMonth,
  getFirstDayOfWeek,
  diffInDays,
  startOfYearMonth,
  getDaysInMonth,
} from "../../../../../utils/calendar/calendarHelper";
import { useRouter } from "next/router";

export const calculateOffset = ({ yearNav, monthNav }) => {
  const firstDay = new Date(yearNav, monthNav, 1).getDay();
  return (firstDay === 0) ? 6 : firstDay - 1;
};

export const weeksInMonth = ({ yearNav, monthNav }) => {
  const nextMonth = (monthNav + 1).toString().padStart(2, 0);
  const current = new Date(`${yearNav}-${nextMonth}`);

  const offset = calculateOffset({ yearNav, monthNav });

  const lengthDaysOfTheMonth = getDaysInMonth(current);
  return Math.ceil((lengthDaysOfTheMonth + offset) / 7);
};

function useScheduleNavigation() {
  const router = useRouter();
  const { query } = router;
  const { yearNav: year, monthNav: month, weekNav: week, day } = query;

  const [nav, setNav] = useState(() => {
    const dayNav = calendar.convertDayNameToNumber(day);
    if (
      !year ||
      !(Number(month) >= 0) ||
      !(Number(week) >= 0) ||
      // !(Number(day) >= 0)
      !day
    ) {
      const current = new Date();

      const firstDayOfMonth = startOfMonth(current);
      const firstDayOfWeek = getFirstDayOfWeek(firstDayOfMonth);

      const offset = diffInDays(firstDayOfMonth, firstDayOfWeek);

      const weekOfMonth = Math.ceil((current.getDate() + offset) / 7);

      return {
        yearNav: +current.getFullYear(),
        monthNav: +current.getMonth(),
        weekNav: +weekOfMonth,
        dayNav: dayNav ?? +calendar.getDayOfWeek(new Date()),
      };
    }

    return {
      yearNav: +year,
      monthNav: +month,
      weekNav: +week,
      dayNav: dayNav ?? +calendar.getDayOfWeek(new Date()),
    };
  });

  const { yearNav, monthNav, weekNav, dayNav } = nav;

  const { setMonthNav, setWeekNav, setDayNav } = useMemo(() => {
    return {
      setMonthNav: (month, year = null) => {
        setNav((prev) => ({
          ...prev,
          monthNav: month,
          ...(year && { yearNav: year }),
        }));
      },
      setWeekNav: (week) => {
        setNav((prev) => {
          const { yearNav, monthNav, dayNav } = prev;
          const weekNav = prev.weekNav + week;

          if (weekNav > 4) {
            const nextMonth = (monthNav + 1).toString().padStart(2, 0);

            const current = new Date(`${yearNav}-${nextMonth}`);

            const offset = calculateOffset({ yearNav, monthNav });

            const lengthDaysOfTheMonth = getDaysInMonth(current);
            const countWeeksInMonth = Math.ceil((lengthDaysOfTheMonth + offset) / 7);

            if (weekNav >= countWeeksInMonth && monthNav === 11) {
              const isNewYear = (weekNav - 1) * 7 + 6 + 1 - offset > lengthDaysOfTheMonth;

              return {
                ...prev,
                ...(isNewYear
                  ? {
                      dayNav: 6,
                      weekNav: (offset + lengthDaysOfTheMonth) % 7 ? 2 : 1,
                      monthNav: 0,
                      yearNav: yearNav + 1,
                    }
                  : {
                      weekNav,
                    }),
              };
            } else if (weekNav > countWeeksInMonth)
              return {
                ...prev,
                dayNav: 6,
                weekNav: (offset + lengthDaysOfTheMonth) % 7 ? 2 : 1,
                monthNav: prev.monthNav + 1,
              };
          } else if (weekNav === 0) {
            const current = new Date(`${yearNav}-${monthNav === 12 ? monthNav : monthNav + 1}`);

            const offset = calculateOffset({ yearNav, monthNav: monthNav - 1 });

            const lengthDaysOfTheMonth = getDaysInMonth(current);

            const countWeeksInMonth = Math.ceil((lengthDaysOfTheMonth + offset) / 7);
            const firstMonthInYear = startOfYearMonth(current);

            if ([firstMonthInYear, 12].includes(monthNav))
              return {
                ...prev,
                dayNav: 6,
                weekNav: (lengthDaysOfTheMonth + offset) % 7 ? countWeeksInMonth - 1 : countWeeksInMonth,
                monthNav: 11,
                ...(monthNav === firstMonthInYear && {
                  yearNav: prev.yearNav - 1,
                }),
              };

            return {
              ...prev,
              dayNav: 6,
              weekNav: (lengthDaysOfTheMonth + offset) % 7 ? countWeeksInMonth - 1 : countWeeksInMonth,
              monthNav: prev.monthNav - 1,
            };
          }

          const currentMonthOffset = calculateOffset({ yearNav, monthNav });
          if ((weekNav - 1) * 7 + dayNav + 1 - currentMonthOffset <= 0) {
            return {
              ...prev,
              ...(monthNav === 0
                ? {
                    weekNav: weeksInMonth({ yearNav, monthNav: 11 }),
                    monthNav: 11,
                    yearNav: prev.yearNav - 1,
                  }
                : {
                    weekNav: weeksInMonth({ yearNav, monthNav: monthNav - 1 }),
                    monthNav: monthNav - 1,
                  }),
            };
          }

          return {
            ...prev,
            weekNav,
          };
        });
      },
      setDayNav: (day) => {
        setNav((prev) => {
          const { yearNav, monthNav, weekNav, dayNav } = prev;
          const nextMonth = (monthNav + 1).toString().padStart(2, 0);
          const current = new Date(`${yearNav}-${nextMonth}`);
          const offset = calculateOffset({ yearNav, monthNav });

          const lengthDaysOfTheMonth = getDaysInMonth(current);
          const countWeeksInMonth = Math.ceil((lengthDaysOfTheMonth + offset) / 7);
          let newDayNav = dayNav + day;
          let newWeekNav = weekNav;

          if (newDayNav === 7) {
            newDayNav = 0;
            newWeekNav++;
          } else if (newDayNav === -1) {
            newDayNav = 6;
            newWeekNav--;
          }

          if ((weekNav - 1) * 7 + dayNav + day + 1 - offset === 0) {
            return {
              ...prev,
              dayNav: newDayNav,

              ...(monthNav === 0
                ? {
                    weekNav: weeksInMonth({ yearNav, monthNav: 11 }),
                    monthNav: 11,
                    yearNav: prev.yearNav - 1,
                  }
                : {
                    weekNav: weeksInMonth({ yearNav, monthNav: monthNav - 1 }),
                    monthNav: monthNav - 1,
                  }),
            };
          }

          const isNewMonth = weekNav === countWeeksInMonth && (weekNav - 1) * 7 + dayNav + day + 1 - offset > lengthDaysOfTheMonth;
          const isNewYear = isNewMonth && monthNav === 11;

          return {
            ...prev,
            dayNav: newDayNav,
            ...(isNewYear
              ? {
                  weekNav: 1,
                  monthNav: 0,
                  yearNav: yearNav + 1,
                }
              : isNewMonth
                ? {
                    weekNav: 1,
                    monthNav: monthNav + 1,
                  }
                : {
                    weekNav: newWeekNav,
                  }),
          };
        });
      },
    };
  }, []);

  const setSelectedDay = useCallback(
    ({ week = weekNav, date, type }) => {
      const month = date.getMonth();
      const day = date.getDay();
      const year = date.getFullYear();

      if ((type === "week" && month <= monthNav) || month === monthNav) {
        return setNav((prev) => ({
          ...prev,
          dayNav: calendar.WEEK_DAYS_FROM_MONDAY[day],
          weekNav: week,
        }));
      }

      if (month > monthNav && year === yearNav) {
        return setNav((prev) => ({
          ...prev,
          dayNav: calendar.WEEK_DAYS_FROM_MONDAY[day],
          weekNav: 1,
          monthNav: month,
        }));
      }

      const nextMonth = (month ? month : 1).toString().padStart(2, 0);
      const current = new Date(`${yearNav}-${nextMonth}`);

      const offset = calculateOffset({ yearNav: year, monthNav: month });

      const lengthDaysOfTheMonth = getDaysInMonth(current);
      const countWeeksInMonth = Math.ceil((lengthDaysOfTheMonth + offset) / 7);

      if (weekNav === 1 && monthNav === 0)
        return setNav((prev) => ({
          ...prev,

          dayNav: calendar.WEEK_DAYS_FROM_MONDAY[day],
          monthNav: month,
          ...(prev.yearNav !== year && {
            yearNav: year,
            weekNav: countWeeksInMonth,
          }),
        }));

      setNav((prev) => ({
        ...prev,
        dayNav: calendar.WEEK_DAYS_FROM_MONDAY[day],

        monthNav: month,
        ...(prev.yearNav !== year ? { yearNav: year, weekNav: 1 } : { weekNav: countWeeksInMonth }),
      }));
    },
    [weekNav, monthNav, yearNav]
  );

  return { nav, setSelectedDay, setWeekNav, setMonthNav, setDayNav };
}

export default useScheduleNavigation;

import { useEffect, useState, useMemo, useRef } from "react";

import useScrollEvent from "./useScrollEvent";
import { useTranslation } from "next-i18next";
import { GET_CLIENT_EVENTS, GET_EVENTS } from "../../../../../graphql/queries/queriesEvent";
import { useAuth } from "../../../../../utils/auth";
import { useRulesData } from "../../../../../utils/apollo/reactivities/useReactivities";
import { useLazyQuery } from "@apollo/client";

export const INITIAL_PAGE_SIZE = 10;

const dateToISO = (year, month, day) => {
  const date = new Date(year, month, day);
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split("T")[0];
};

const createRequestArrayDefault = ({ firstDay = 0, lengthIteration = 0, monthNav = 0, yearNav = 0, hallId = 0, zone = 0 }) => {
  let arrayDates = [];

  for (let i = 0; i < lengthIteration; i++) {
    const activeDateWeek = dateToISO(yearNav, monthNav, firstDay + i);

    arrayDates.push({
      startDate: activeDateWeek,
      endDate: activeDateWeek,
      hallId: +hallId,
      zone: +zone,
    });
  }

  return arrayDates;
};

export const useEventsByHall = ({
  hallId,
  activeBlockOfContent,
  nav: { yearNav = "", monthNav = "", weekNav = "", dayNav = "" } = {},
  activity,
  instructor,
  template,
  time,
  zone,
  toggleButtons,
}) => {
  const {
    i18n: { language },
  } = useTranslation();

  const didMountRef = useRef(false);

  const { isAuthorized, accessToken } = useAuth();
  const rulesAccepted = useRulesData();

  const [{ showRequestBlock, isRequested, queryOptions }, setState] = useState({
    showRequestBlock: false,
    isRequested: false,
    queryOptions: {},
  });

  useEffect(() => {
    if (didMountRef.current) return reset();

    didMountRef.current = true;
  }, [language, zone, activeBlockOfContent, hallId, activity, instructor, template, toggleButtons]);

  const { page, reset, finishLoading, successLoaded } = useScrollEvent({
    activeBlockOfContent,
    isRequested,
  });

  const [allEvents, setEvents] = useState([]);

  const requestInfo = useMemo(() => {
    if (!yearNav || !(Number(monthNav) >= 0) || !(Number(weekNav) >= 0) || !(Number(dayNav) >= 0) || !activeBlockOfContent || !hallId)
      return { key: null, data: [], activeBlockOfContent };

    const key =
      activeBlockOfContent === "list"
        ? `${+yearNav}-${+monthNav}-${+weekNav}-${+dayNav}-${activeBlockOfContent}-${page}`
        : `${+yearNav}-${+monthNav}-${+weekNav}-${+dayNav}-${activeBlockOfContent}`;

    const beforeDaysLengthDefault = new Date(yearNav, monthNav, 1).getDay() - 1,
      beforeDaysLength = beforeDaysLengthDefault == -1 ? 6 : beforeDaysLengthDefault,
      activeDay = (weekNav - 1) * 7 + +dayNav - +beforeDaysLength + 1,
      activeDate = new Date(yearNav, monthNav, activeDay);

    const { isMorningTime, isAfternoonTime, isEveningTime, isMyVisit } = toggleButtons;

    let firstDay = null;

    switch (activeBlockOfContent) {
      case "list":
        firstDay = activeDate.getDate() + 1;

        const additionalOptions = {
          isMorningTime,
          isAfternoonTime,
          isEveningTime,
        };

        if (isAuthorized && isMyVisit) additionalOptions.myVisits = isMyVisit;

        return {
          key,
          activeBlockOfContent,
          data: [
            {
              ...additionalOptions,
              pageSize: INITIAL_PAGE_SIZE,
              page,
              hallId: +hallId,
              instructor: +instructor,
              activity: +activity,
              template: +template,
              time: /[0-9]{2}:[0-9]{2}/.test(time) ? time : null,
              zone: +zone,
            },
          ],
        };

      case "day":
        firstDay = activeDate.getDate();

        return {
          key,
          activeBlockOfContent,
          data: createRequestArrayDefault({
            firstDay,
            lengthIteration: 1,
            yearNav,
            monthNav,
            hallId: +hallId,
            zone: +zone,
          }),
        };

      case "week": {
        firstDay = (weekNav - 1) * 7 - beforeDaysLength + 1;

        const startDate = dateToISO(yearNav, monthNav, firstDay);

        const endDate = dateToISO(yearNav, monthNav, firstDay + 7 - 1);

        return {
          key,
          activeBlockOfContent,
          data: [
            {
              startDate,
              endDate,
              hallId: +hallId,
              zone: +zone,
            },
          ],
        };
      }

      case "month":
        const lastDayOfMonth = new Date(yearNav, monthNav + 1, 0).getDate(),
          lengthOfWeeks = Math.ceil((beforeDaysLength + lastDayOfMonth) / 7);

        firstDay = -beforeDaysLength + 2;
        const lengthIteration = lengthOfWeeks * 7;

        const startDate = dateToISO(yearNav, monthNav, 1);

        const endDate = dateToISO(yearNav, monthNav, firstDay + lengthIteration - 1);

        return {
          key,
          activeBlockOfContent,
          data: [
            {
              startDate,
              endDate,
              hallId: +hallId,
              zone: +zone,
            },
          ],
        };

      default:
        return { key: null, activeBlockOfContent, data: [] };
    }
  }, [yearNav, monthNav, weekNav, dayNav, page, activeBlockOfContent, hallId, zone, toggleButtons, activity, instructor, template, time]);

  const [getEvents] = useLazyQuery(rulesAccepted ? GET_CLIENT_EVENTS : GET_EVENTS, {
    fetchPolicy: "cache-and-network",
    variables: { ...(accessToken ? { accessToken } : {}) },
  });

  useEffect(() => {
    if (!requestInfo?.data?.length || !requestInfo?.key) return setEvents([]);

    const { data, activeBlockOfContent } = requestInfo;
    const isListType = activeBlockOfContent == "list";

    isListType
      ? setState((s) => ({
          ...s,
          isRequested: true,
        }))
      : setState({
          queryOptions: {},
          isRequested: false,
          showRequestBlock: false,
        });

    data.forEach(async (variables) => {
      await getEvents({ variables }).then(
        ({ data: { events } = {} }) => {
          if (!events) return;

          if (isListType && page > 1) {
            setEvents((prev) => {
              if (prev.length) {
                const data = [...prev];
                for (const event of events) {
                  const { id } = event;

                  if (data.every(({ id: prevId }) => prevId !== id)) {
                    data.push(event);
                  } else {
                    const oldElIndex = data.findIndex(({ id: currentId }) => currentId === id);
                    if (oldElIndex > -1) data[oldElIndex] = event;
                  }
                }

                return data;
              }
              return events;
            });
          } else setEvents(events);

          setState({
            showRequestBlock: true,
            isRequested: false,
            queryOptions: variables,
          });

          // Вызываем при последнем запросе
          if (isListType) {
            const hasRichedMax = INITIAL_PAGE_SIZE > events.length;
            if (hasRichedMax) return finishLoading();
            return successLoaded();
          }
        },

        ({ message }) => {
          console.log(message);
        }
      );
    });
  }, [page, rulesAccepted, requestInfo]);

  return { allEvents, queryOptions, showRequestBlock, setEvents };
};

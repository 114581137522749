import { useCallback, useEffect, useState } from "react";

const MEDIA_SIZES = [
  { firstSize: 0, lastSize: 480, elementBySize: "mobile" },
  { firstSize: 481, lastSize: 767, elementBySize: "day" },
];

export const useMediaSize = (media = MEDIA_SIZES) => {
  const getMediaElement = useCallback(
    () =>
      media.find(({ firstSize, lastSize }) =>
        window.innerWidth > firstSize && window.innerWidth <= lastSize
      ) ?? {},
    [media]
  );

  const [activeMediaElement, setActiveMediaElement] = useState(
    getMediaElement()
  );

  useEffect(() => {
    const handler = () => {
      const nextElement = getMediaElement();

      if (nextElement?.elementBySize === activeMediaElement?.elementBySize)
        return;

      setActiveMediaElement(nextElement);
    };

    window.addEventListener("resize", handler);

    return () => window.removeEventListener("resize", handler);
  }, [getMediaElement]);

  return activeMediaElement;
};

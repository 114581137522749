import React, { useMemo, useState, memo, useRef } from "react";

import { configLanguage, getDayOfWeek } from "../../ToggleBlockOfData/calendarUtil";

import { InstructorBlock } from "..";

import CancelTraining from "../CancelTraining/CancelTraining";

import TrainingHeaderBlock from "../TrainingHeaderBlock/TrainingHeaderBlock";
import TrainingAboutBlock from "../TrainingAboutBlock/TrainingAboutBlock";
import LeadButtonsBlock from "./LeadButtonsBlock";

import TrainingCancelSection from "../TrainingCancelSection/TrainingCancelSection";
import VisitorsBlock from "../VisitorsBlock";

import { ChooseProfile } from "../../SubscriptionPageContent/ChooseProfile";

import { useTranslation } from "next-i18next";
import { useAuth } from "../../../../../../utils/auth";

import { PaymentBlockContainer } from "../Payment/PaymentBlockContainer";
import { useFamilyData, useRulesData } from "../../../../../../utils/apollo/reactivities";

import { TrainingBottomNav } from "./TrainingBottomNav";
import { PromocodeBlock } from "../../../../../common/Promocode/PromocodeBlock";

const TrainingPage = memo(({ data, familyProfileId, setFamilyId, promocode, setPromocode, hasOwnPromocode }) => {
  const [t, { language }] = useTranslation();

  const rulesAccepted = useRulesData();

  const family = useFamilyData();

  const { isAuthorized } = useAuth();

  const {
    title,
    image,
    enrollEnabled: isActiveTraining,
    promocodesEnabled,
    duration,
    description,
    descriptionHtml,
    date: eventDate,
    instructors = [],
    hall: { title: nameHall, address },
    payments = [],
    cards,
    priceInfo,
    template: { priceEnabled },
    status,
    activity,
    visitSet = [],
    visitors = [],
    options = [],
    guestVisitEnabled = false,
    id,
    pilotEnabled: showLeadButton,
    phoneConfirmationRequired,
  } = data;
  const activityTitle = activity?.title ?? "";

  const { dateTime } = useMemo(() => {
    const date = new Date(eventDate);
    const dateYear = date.getFullYear(),
      dateMonth = configLanguage[language].monthNamesForWeekShort[date.getMonth()].slice(0, -1),
      dateDay = configLanguage[language].weekDayNames[getDayOfWeek(date)];

    const toTwoDigit = (value) => ("0" + value).slice(-2);
    const dateDate = toTwoDigit(date.getDate()),
      dateHours = toTwoDigit(date.getHours()),
      dateMinutes = toTwoDigit(date.getMinutes());

    return {
      dateTime: `${dateDay}, ${dateDate} ${dateMonth} ${dateYear}, ${dateHours}:${dateMinutes}`,
    };
  }, [eventDate, language]);

  const showPayment = useMemo(() => {
    if (!payments?.length || !rulesAccepted) return false;

    return true;
  }, [payments, rulesAccepted]);

  const [cancelTrainingData, setCancelTrainingData] = useState({});

  const refPayment = useRef();

  return (
    <>
      {!!cancelTrainingData?.message && <CancelTraining data={cancelTrainingData} setCancelTrainingData={setCancelTrainingData} />}

      <div className="training">
        <TrainingHeaderBlock image={image} title={title} address={address} nameHall={nameHall} />

        <TrainingAboutBlock
          duration={duration}
          priceInfo={priceInfo}
          priceEnabled={priceEnabled}
          description={description}
          descriptionHtml={descriptionHtml}
          dateTime={dateTime}
          activityTitle={activityTitle}
          title={title}
        />

        {!!instructors?.length && <InstructorBlock instructors={instructors} />}

        <section ref={refPayment} id="_payment" className="training-payment">
          <div className="training-wrapper training__wrapper--grey">
            <div className="container">
              <LeadButtonsBlock
                isAuthorized={isAuthorized}
                isActiveTraining={isActiveTraining}
                showLeadButton={showLeadButton}
                onRequest={setCancelTrainingData}
                status={status}
                rulesAccepted={rulesAccepted}
              />

              {!!visitSet?.length && (
                <TrainingCancelSection
                  eventId={id}
                  visitSet={visitSet}
                  setCancelTrainingData={setCancelTrainingData}
                  phoneConfirmationRequired={phoneConfirmationRequired}
                  options={options}
                  optionsTitle={t("additional_options")}
                  guestVisitEnabled={guestVisitEnabled}
                  familyProfileId={familyProfileId}
                />
              )}

              {family.length > 1 && rulesAccepted && isAuthorized && (
                <ChooseProfile
                  onClick={setFamilyId}
                  title={t("profile_choosed")}
                  items={family}
                  activeId={familyProfileId}
                  className="training-family__title-block"
                  titleClassName="training-options__title"
                  dropdownClassName="training-family__dropdown"
                />
              )}
              {!!promocodesEnabled && showPayment && (
                <PromocodeBlock setPromocode={setPromocode} promocode={promocode} hasOwnPromocode={hasOwnPromocode} />
              )}
              {showPayment && (
                <PaymentBlockContainer
                  className="training-block-swiper training-block-swiper-flex"
                  visitData={data}
                  isAdditionalPayment={!!visitSet?.length}
                  payments={payments}
                  promocode={promocodesEnabled ? promocode : ""}
                  cards={cards}
                  options={options}
                  optionsTitle={t("additional_options")}
                  familyProfileId={familyProfileId}
                  onResponse={setCancelTrainingData}
                />
              )}
            </div>
          </div>
        </section>

        {!!visitors?.length && <VisitorsBlock data={visitors} title={t("visitors")} subTitle={t("details")} />}
      </div>
      <TrainingBottomNav rulesAccepted={rulesAccepted} isAuthorized={isAuthorized} isActiveTraining={isActiveTraining} refEl={refPayment} />
      <style jsx global>{`
        @import "./TrainingPage.scss?5";
        @import "../VisitorsBlock/VisitorsBlock.scss?3s";
      `}</style>
    </>
  );
});

export { TrainingPage };
